/**
 * fNum format number with comma and decimal places
 * @param  {Number} decimal_places optional Number default "2"
 * @return {String} comma separated
 */

import { format } from 'date-fns'

Number.prototype.fNum = function (decimal_places=2) {
    return this.toLocaleString('en-GB', {
        minimumFractionDigits: decimal_places,
        maximumFractionDigits: decimal_places,
    })
}

String.prototype.ucfirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase()
}

Array.prototype.sortAscArrayOfObjectBy = function (key) {
    if (typeof key == 'string' && key.includes('.')) {
        try {
            const [index, key_name] = key.split('.')
            return this.sort((a,b) => (a[index][key_name] > b[index][key_name]) ? 1 : ((b[index][key_name] > a[index][key_name]) ? -1 : 0))
        } catch (error) {
            return this
        }
    } else {
        return this.sort((a,b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
    }
}

Array.prototype.sortAscBy = function (key) {
    // sort by key using localeCompare with base sensitivity
    return this.sort((a, b) => {
        const compareA = getKey(a, key)
        const compareB = getKey(b, key)

        if (compareA === null || compareB === null) {
            return compareA === null ? 1 : -1
        }

        return compareA.localeCompare(compareB, undefined, { sensitivity: 'base' })
    })
}

function getKey(obj, key) {
    if (typeof key !== 'string') return null

    const keys = key.split('.')
    for (const k of keys) {
        obj = obj?.[k]
        if (obj === undefined) return null
    }

    return obj
}

// Date.prototype.uniformFormat = function () {
//     var fDate = this.toLocaleDateString('en-GB', {
//         day : '2-digit',
//         month : 'short',
//         year : 'numeric',
//     }).split(' ').join(' ')

//     if (fDate === 'Invalid Date') {
//         return ''
//     } else {
//         const [dd, mmm, yyyy] = fDate.split(' ')
//         if (mmm?.toString()?.length > 3) {
//             fDate = `${dd} ${mmm?.toString()?.substr(0,3)} ${yyyy}`
//         }
//     }

//     return fDate
// }

Date.prototype.uniformFormat = function (
    {
        includeTime = false,
    } = {}
) {
    var fDate = this.toLocaleDateString('en-GB', {
        day : '2-digit',
        month : 'short',
        year : 'numeric',
    }).split(' ').join(' ');

    if (fDate === 'Invalid Date') {
        return '';
    } else {
        const [dd, mmm, yyyy] = fDate.split(' ');
        if (mmm?.toString()?.length > 3) {
            fDate = `${dd} ${mmm?.toString()?.substr(0,3)} ${yyyy}`;
        }
    }

    if (includeTime) {
        const hours = this.getHours().toString().padStart(2, '0');
        const minutes = this.getMinutes().toString().padStart(2, '0');
        fDate += ` ${hours}:${minutes}`;
    }

    return fDate
}

String.prototype.tzFormat = function(timeZone, lang=undefined, options=null){

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/

    if(!!this) {
        if(dateRegex.test(this)){
            const dateParts = this.split("-");
            const formattedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
            const dateObject = new Date(formattedDate);
            if(options) {
                let intlDateObj = new Intl.DateTimeFormat(lang, options)
                return intlDateObj.format(dateObject)
            }
            return format(dateObject, 'dd MMM yyyy', { timeZone: timeZone })
        }

        if(dateTimeRegex.test(this)){
            const dateParts = this.split(' ') // Split date and time
            const date = new Date(dateParts[0] + 'T' + dateParts[1] + 'Z')
            return new Date(date).tzFormat(timeZone, lang);
        }
    }
    return this;
}

Date.prototype.tzFormat = function(timeZone, lang=undefined, options=null){
    let intlDateObj

    if(options != null){
        intlDateObj = new Intl.DateTimeFormat(lang, options)
        return intlDateObj.format(this)
    }
    if(lang!=undefined){
        intlDateObj = new Intl.DateTimeFormat(lang, {
            // timeZone: timeZone,
            day: '2-digit',
            month: 'short',
            year: 'numeric',

        });
        return intlDateObj.format(this)
    }else{
        intlDateObj = new Intl.DateTimeFormat(lang, {
            // timeZone: timeZone,
        });
        return intlDateObj.format(this)
    }
}

Date.prototype.toFormatDate = function (separator = '-', lang = null) {
    // Helper function to pad numbers with leading zeros
    const pad = num => num.toString().padStart(2, '0');

    // Get the year, month, and day from the Date object
    const year = this.getFullYear();
    const month = pad(this.getMonth() + 1); // Pad the month with leading zeros
    const day = pad(this.getDate()); // Pad the day with leading zeros

    if(lang) {
        const options = { month: 'short' };
        const monthShort = new Intl.DateTimeFormat('en-US', options).format(new Date(year, this.getMonth()));
        return `${day}${separator}${monthShort}${separator}${year}`;
    }

    // Return the formatted date string in the desired format (e.g., "YYYY-MM-DD")
    return `${year}${separator}${month}${separator}${day}`;
}

Date.prototype.toFormatDateToISO = function () {
    // Helper function to pad numbers with leading zeros
    const pad = num => num.toString().padStart(2, '0');

    // Get the year, month, and day from the Date object
    const year = this.getUTCFullYear();
    const month = pad(this.getUTCMonth() + 1); // Pad the month with leading zeros
    const day = pad(this.getUTCDate()); // Pad the day with leading zeros

    // Get the current UTC time in hours, minutes, and seconds and pad them with leading zeros
    const hours = pad(new Date().getUTCHours());
    const minutes = pad(new Date().getUTCMinutes());
    const seconds = pad(new Date().getUTCSeconds());

    // Return the formatted date string in ISO format (e.g., "YYYY-MM-DD HH:MM:SS")
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

//Alternative for toFixed()
Number.prototype.roundNum = function(digit = 2) {
    const formattedString = Number(this).fNum(digit)
    return Number(formattedString.replaceAll(',', ''));
}